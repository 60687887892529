<template>
    <!-- Contact Us Form -->
    <v-form ref="form" :class="isDark?'custom-error-color-dark':'custom-error-color-light'" v-model="valid" lazy-validation @submit.prevent="submit">
      <div>
          <v-text-field :dark="isDark"
          v-model="contact.name"
          color="custom-accent darken-4"
          :label="$t('name')"
          :placeholder="$t('name')"
          required
          :rules="textfieldRules"
          :counter="maxTextfieldLength"
          
          outlined
          dense
          ></v-text-field>
      </div>
      <div>
          <v-text-field :dark="isDark"
          v-model="contact.email"
          color="custom-accent darken-4"
          :label="$t('email')"
          :placeholder="$t('email')"
          required
          :rules="emailfieldRules"
          :counter="maxTextfieldLength"
          
          outlined
          dense
          ></v-text-field>
      </div>
      <div>
          <v-text-field :dark="isDark"
          v-model="contact.phone"
          color="custom-accent darken-4"
          :label="$t('phone')"
          :placeholder="$t('phone')"
          required
          :rules="phonefieldRules"
          :counter="maxTextfieldLength"
          
          outlined
          dense
          ></v-text-field>
      </div>
      <div>
        <v-text-field :dark="isDark"
          v-model="contact.subject"
          color="custom-accent darken-4"
          :label="$t('subject')"
          :placeholder="$t('subject')"
          required
          :rules="textfieldRules"
          :counter="maxTextfieldLength"
          
          outlined
          dense
          ></v-text-field>
      </div>
      <div>
        <v-textarea :dark="isDark"
          v-model="contact.content"
          color="custom-accent darken-4"
          :label="$t('content')"
          :placeholder="$t('content')"
          auto-grow
          rows="4"
          row-height="30"
          required
          :rules="textareaRules"
          :counter="maxTextareaLength"
          
          outlined
          dense
          ></v-textarea>
      </div>
      <div >
          <v-btn class="mr-2 success" type="submit" :title="$t('submit')" :loading="loading" :dark="isDark" :disabled="!valid" text>
          <span>{{$t("submit")}}</span>
          </v-btn>
          <v-btn :title="$t('clear')" text @click="clear" :dark="isDark" depressed>
          {{$t("clear")}}
          </v-btn>
      </div>
    </v-form>
    <!-- Contact Us Form -->
</template>
<script>
import { mapState,mapActions} from "vuex";

export default {
  name: "ContactUsForm", 
  props: ['isDark','type'],

  data() {
    return {
        contact:{},
        valid: false,
    }
  },
  components:{
  },
  created(){
  },
  computed:{

    maxTextfieldLength () {
    return 200;
    },
    maxTextareaLength () {
    return 3500;
    },
    textfieldRules(){ 
        return [
            v => !!v || this.$t('fieldError'),
            v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
        ]
    },
    emailfieldRules(){ 
        const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return [
            v => !!v || this.$t('fieldError'),
            v => emailRegex.test(v) || this.$t('emailValidError'),
        ]
    },
    phonefieldRules(){ 
        const phoneRegex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
        return [
            v => !!v || this.$t('fieldError'),
            v => phoneRegex.test(v) || this.$t('phoneValidError'),
        ]
    },
    textareaRules(){ 
        return [
            v => !!v || this.$t('fieldError'),
            v => v?.length <= this.maxTextareaLength || this.$t('fieldValidError1')+' '+this.maxTextareaLength+' '+this.$t('fieldValidError2'),
        ]
    },
    ...mapState("contact", {
      loading: "loading"
    }),

  },
  watch:{
  },
  methods:{
    submit () {
      if(this.$refs.form.validate()){
        let contactInfo={
          name:this.contact.name,
          email:this.contact.email,
          phone:this.contact.phone.replace(/\D/g,""),
          subject:this.contact.subject,
          content:this.contact.content,
          type:this.type,
        };

        this.addContact(contactInfo).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('messagesent'), title:this.$t('success'), type:'success'});
            this.clear();
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        )

      }
    },
    clear () {
      this.$refs.form.reset();
      this.contact={};
    },

    ...mapActions("contact", ["addContact"]),
  },
};
</script>
<style scoped>
@import url('../../assets/styles/contact.css');
</style>
 
